import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EquationNodeViewComponent } from '@portal-core/text-editor/components/equation-node-view/equation-node-view.component';
import { EquationModule } from '@portal-core/ui/equation/equation.module';

@NgModule({
  declarations: [
    EquationNodeViewComponent
  ],
  imports: [
    CommonModule,
    EquationModule
  ],
  exports: [
    EquationNodeViewComponent
  ]
})
export class EquationNodeViewModule { }
