import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ENTER, SPACE } from '@angular/cdk/keycodes';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DoCheck, ElementRef, EventEmitter, HostBinding, HostListener, Input, OnDestroy, Optional, Output, Self, ViewChild, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { MatLegacyButton as MatButton } from '@angular/material/legacy-button';
import { LegacyCanUpdateErrorState as CanUpdateErrorState, LegacyErrorStateMatcher as ErrorStateMatcher, legacyMixinErrorState as mixinErrorState, LegacyThemePalette as ThemePalette } from '@angular/material/legacy-core';
import { MatLegacyFormFieldControl as MatFormFieldControl } from '@angular/material/legacy-form-field';
import { FileService } from '@portal-core/general/services/file.service';
import { FlareSpecialCharsRegex } from '@portal-core/project-files/constants/file-regexes.constants';
import { FileWithError } from '@portal-core/project-files/models/file-with-error.model';
import { InputObservable } from '@portal-core/util/input-observable.decorator';
import { noop } from 'lodash';
import { Observable, skip, Subject } from 'rxjs';

/** Necessary for mixing in CanUpdateErrorState. */
class FilePickerBase {
  readonly stateChanges = new Subject<void>();

  constructor(
    public _defaultErrorStateMatcher: ErrorStateMatcher,
    public _parentForm: NgForm,
    public _parentFormGroup: FormGroupDirective,
    public ngControl: NgControl
  ) { }
}

/** Mixin Material's CanUpdateErrorState to help with updating the error state for MatFormFieldControl */
const FilePickerMixinBase: Constructor<CanUpdateErrorState> & typeof FilePickerBase = mixinErrorState(FilePickerBase);

// Used to generate unique ids which are required by MatFormFieldControl.
let filePickerFormFieldControlId: number = 0;

@Component({
  selector: 'mc-file-picker',
  templateUrl: './file-picker.component.html',
  styleUrls: ['./file-picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    // This is the normal way to setup the component for use with Angular Forms. Due to a cyclic dependency it is being done in the constructor instead.
    // { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => FilePickerComponent), multi: true },
    { provide: MatFormFieldControl, useExisting: FilePickerComponent }
  ]
})
export class FilePickerComponent extends FilePickerMixinBase implements OnDestroy, DoCheck, CanUpdateErrorState, ControlValueAccessor, MatFormFieldControl<FileWithError[]> {
  /** The file types the file input should accept. Passed directly to the underlying input[type="file"] */
  @Input() accept: string;

  /** The theme color of the file picker. */
  @Input() color: ThemePalette = 'link' as ThemePalette;

  @Input() folderPathLength?: number = 0;
  @InputObservable('folderPathLength') folderPathLength$: Observable<number>;


  @Input() maxFilePathLength?: number;
  @Input() warningFilePathLength?: number;
  @Input() layout?: 'button' | 'field' = 'button';

  /**
   * A path where a file will be stored on a server, for example: /Content/Images/
   * It is used as prefix to file name in UI in the Field layout, and when returning a File object from the component
  */
  @Input() path?: string = '';

  /**
   * It indicates if a path is supposed to start with a slash, which is a commong thing in Flare projects, for example: /Project/TOCs/Flare.fltoc
   * so in this case we show a value in the picker without leading slash but preserve it in the value property
   * By default it is false so not to change the behavior in the existing components
  */
  @Input() isPathWithLeadingSlash?: boolean = false;

  /** Whether the file picker accepts multiple files. */
  @Input()
  get multiple(): boolean {
    return this._multiple;
  }
  set multiple(multiple: boolean) {
    if (this._multiple !== multiple) {
      this._multiple = coerceBooleanProperty(multiple);
      this.stateChanges.next();
    }
  }
  private _multiple: boolean = false;

  /** Whether the file picker is disabled. */
  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(disabled: boolean) {
    if (this._disabled !== disabled) {
      this._disabled = coerceBooleanProperty(disabled);
      this.stateChanges.next();
    }
  }
  private _disabled: boolean = false;

  /** The label displayed on the file picker. */
  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(placeholder: string) {
    if (this._placeholder !== placeholder) {
      this._placeholder = placeholder;
      this.stateChanges.next();
    }
  }
  private _placeholder: string = 'Choose a File';

  /** Whether the file picker is required in a form. Required by MatFormFieldControl. */
  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(required: boolean) {
    if (this._required !== required) {
      this._required = coerceBooleanProperty(required);
      this.stateChanges.next();
    }
  }
  private _required: boolean = false;

  /** The current value of the file picker. */
  @Input()
  get value(): FileWithError[] {
    return this._value;
  }
  set value(value: FileWithError[]) {
    if (this._value !== value) {
      this._value = value;
      this.valueChange.emit(value);
      this.stateChanges.next();
      this.cdr.markForCheck();
    }
  }
  private _value: FileWithError[];

  /** Emits when the value changes (either due to user input or programmatic change). */
  @Output() valueChange: EventEmitter<FileWithError[]> = new EventEmitter<FileWithError[]>();

  /** Sets the aria-describedby attribute on the file picker. Required by MatFormFieldControl */
  @HostBinding('attr.aria-describedby') describedBy: string = '';

  /** Applies the mc-form-control-has-value class to the host element when the picker has a valid value. */
  @HostBinding('class.mc-form-control-has-value')
  get hasValueHostClass(): boolean {
    return !!this.value;
  }

  /** Applies the mc-file-picker class to the host element. */
  @HostBinding('class.mc-file-picker') hostClass: boolean = true;

  /** Adds an id to the element. Required by MatFormFieldControl. */
  @HostBinding() id: string = `mc-file-picker-${filePickerFormFieldControlId++}`;

  /** The underlying input[type="file"] element. */
  @ViewChild('fileInput') fileInputRef: ElementRef<HTMLInputElement>;

  /** The button that triggers the browser's file selector. */
  @ViewChild('inputButton') inputButton: MatButton;

  /** Applies the mc-form-control-empty class to the host element when the picker has no value. */
  @HostBinding('class.mc-form-control-empty')
  /** Whether the file picker has no value. Required by MatFormFieldControl. */
  get empty(): boolean {
    return !this.value;
  }

  /** Required by MatFormFieldControl */
  readonly controlType: string = 'mc-file-picker';
  /** Required by MatFormFieldControl */
  focused: boolean;
  /** Required by MatFormFieldControl */
  get shouldLabelFloat(): boolean {
    return !!this.value;
  }
  /** The stateChanges Observable emits when the state of the file picker changes (value, disabled, required, etc). Required by MatFormFieldControl. */
  readonly stateChanges: Subject<void> = new Subject<void>();

  /** The value change callback for Angular Forms */
  protected onChange: Function = noop;
  /** The control touched callback for Angular Forms */
  protected onTouched: Function = noop;

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    @Optional() public parentForm: NgForm,
    @Optional() public parentFormGroup: FormGroupDirective,
    public defaultErrorStateMatcher: ErrorStateMatcher,
    protected cdr: ChangeDetectorRef,
    private focusMonitor: FocusMonitor,
    private elementRef: ElementRef<HTMLElement>,
    protected fileService: FileService
  ) {
    super(defaultErrorStateMatcher, parentForm, parentFormGroup, ngControl);

    // Replace the provider from above with this. This is a workaround for a cyclic dependency error as explained here:
    // https://material.angular.io/guide/creating-a-custom-form-field-control#-code-ngcontrol-code-
    if (this.ngControl) {
      // Setting the value accessor directly (instead of using the providers) to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    }

    this.focusMonitor.monitor(this.elementRef.nativeElement, true).subscribe(origin => {
      this.focused = !!origin;
      this.stateChanges.next();
    });
  }

  ngOnInit() {
    // Skip the prefilled initial value and recheck errors with new folder length
    this.folderPathLength$.pipe(skip(1))
      .subscribe(() => this.setValueFromUI(this.checkFileNamesForErrors(this.folderPathLength, this.value)));
  }

  /** Necessary to update the error state for MatFormFieldControl. */
  ngDoCheck(): void {
    if (this.ngControl) {
      // We need to re-evaluate this on every change detection cycle, because there are some
      // error triggers that we can't subscribe to (e.g. parent form submissions). This means
      // that whatever logic is in here has to be super lean or we risk destroying the performance.
      this.updateErrorState();
    }
  }

  /** Stops listening to focus events and completes the state changes observable for MatFormFieldControl. */
  ngOnDestroy() {
    this.focusMonitor.stopMonitoring(this.elementRef.nativeElement);
    this.stateChanges.complete();
  }

  /** Handles keyboard events to open the file selection dialog. */
  @HostListener('keydown', ['$event'])
  onKeydown(event: KeyboardEvent) {
    // If the enter/space key is pressed
    if (event.keyCode === ENTER || event.keyCode === SPACE) {
      // Opens the file selection dialog
      this.onContainerClick(null);
    }
  }

  onFileChipRemoved(index: number) {
    // Make a clone to replace the reference since splicing alone won't call set value
    const valCopy = this.value.slice();
    valCopy.splice(index, 1);
    this.setValueFromUI(valCopy.length ? valCopy : null);
    this.cdr.detectChanges();
  }

  /** Handles the file changed event to update the value of the file picker. */
  onFileChanged(event: Event) {
    const input = (event.target as HTMLInputElement);
    const files: File[] = Array.from(input.files);
    input.value = null; // Clear out the input element's value so that a change is detected if the user picks the same file again
    const filesWithPath = files.map(file => this.fileService.createFileWithPath(file, file.name, this.path));
    this.setValueFromUI(filesWithPath);
  }

  /** Handles the remove file event to clear out the value on the file picker. */
  onRemoveFileClicked(index: number) {
    this.inputButton.focus();
    if (this.value.length === 1) {
      this.setValueFromUI(null);
    } else {
      const newValue = [...this.value];
      newValue.splice(index, 1);
      this.setValueFromUI(newValue);
    }
  }

  /** Sets the value and path properties on the file picker and notifies Angular Forms of the changes.  */
  protected setValueFromUI(value: FileWithError[]) {
    this.value = value;
    this.onChange(this.multiple || !Array.isArray(value) ? this.value : [this.value[0]]); // So Angular Forms know this control's value has changed
    this.onTouched(); // So Angular Forms know this control has been touched
    this.cdr.detectChanges(); // Detect changes to not have "Expression has changed after it was checked." error, since the focused element might be changed or removed from DOM
  }

  /** Required by ControlValueAccessor */
  writeValue(value: FileWithError[]): void {
    this.value = this.multiple || !Array.isArray(value) ? value : [value[0]];
  }

  /** Required by ControlValueAccessor */
  registerOnChange(fn: Function): void {
    this.onChange = fn || noop;
  }

  /** Required by ControlValueAccessor */
  registerOnTouched(fn: Function): void {
    this.onTouched = fn || noop;
  }

  /** Required by ControlValueAccessor */
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /** Required by MatFormFieldControl */
  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  /** Required by MatFormFieldControl */
  onContainerClick(event: MouseEvent): void {
    if (this.layout === 'field') {
      this.fileInputRef.nativeElement.click();
    }
  }

  checkFileNamesForErrors(folderPathLength: number, files: FileWithError[]): FileWithError[] {
    if (files == null) {
      return null;
    }
    // sets the warning icon on the row with this file.
    return files.map((file) => {
      if (this.maxFilePathLength && this.warningFilePathLength) {
        const filePath = folderPathLength + file.name.length;
        if (filePath > this.maxFilePathLength) {
          file.lengthWarning = false;
          file.lengthError = true;
        }
        else if (filePath > this.warningFilePathLength) {
          file.lengthWarning = true;
        } else {
          file.lengthWarning = false;
          file.lengthError = false
        }
      }
      if (!file.name.match(FlareSpecialCharsRegex)) {
        file.specialCharError = true;
      } else {
        file.specialCharError = false;
      }
      return file;
    });
  }
}
