import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EquationComponent } from '@portal-core/ui/equation/equation.component';

@NgModule({
  declarations: [
    EquationComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    EquationComponent
  ]
})
export class EquationModule { }
