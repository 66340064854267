import { isEmpty, isEqual, isEqualWith } from 'lodash';
import { Fragment, Mark, Node, NodeRange, NodeType, ProseMirrorNode, ResolvedPos, Schema, Slice } from 'prosemirror-model';
import { resolvedPosFind } from './resolved-pos';

export declare type IterateNodeCallback = (child: Node, data: any, pos: number, parent: Node, childIndex: number) => any;
export declare type FindNodeCallback = (node: Node, position: number) => any;
export declare type SelectBucketCallback = (node: Node, parent: Node, pos: number) => any;

export interface FoundNodeInfo {
  node: Node;
  parent?: Node;
  pos: number;
}

export class NodeMapValue implements FoundNodeInfo {
  node: ProseMirrorNode;
  pos: number;
  index: number;
  parentPos: number;
  children: NodeMapValue[];
  constructor(node: ProseMirrorNode, pos: number, index: number, parentPos: number) {
    this.node = node;
    this.pos = pos;
    this.index = index;
    this.parentPos = parentPos;
    this.children = [];
  };
}

/*
 * Finding nodes
 */
export function findNode(node: Node, predicate: FindNodeCallback): FoundNodeInfo {
  let foundData: FoundNodeInfo;

  // Loop through the node's descendants
  node.descendants((child: Node, pos: number, parent: Node) => {
    if (predicate(child, pos)) {
      foundData = {
        node: child,
        parent: parent,
        pos: pos
      };

      return false; // break the descendants loop
    }
  });

  return foundData;
}

export function createNodeBuckets(node: Node, predicate: SelectBucketCallback, bucketPerdicate: SelectBucketCallback): Dictionary<FoundNodeInfo[]> {
  const buckets: Dictionary<FoundNodeInfo[]> = {};

  // Loop through the node's descendants
  node.descendants((child: Node, pos: number, parent: Node) => {
    if (predicate(child, parent, pos)) {
      const data = { node: child, parent: parent, pos: pos };
      // fill up bucket or create it
      const bucket = bucketPerdicate(child, parent, pos);
      if (buckets[bucket]) {
        buckets[bucket].push(data);
      } else {
        buckets[bucket] = [data];
      }
    }
  });

  return buckets;
}

export function hasNode(node: Node, predicate: FindNodeCallback): boolean {
  return !!findNode(node, predicate);
}

export function hasInlineNode(node: Node): boolean {
  return hasNode(node, child => child.isInline);
}

export function isOrHasInlineNode(node: Node): boolean {
  return node.isInline || hasInlineNode(node);
}

export function containsInlineNode(from: number, to: number, nodeType: NodeType, doc: Node): boolean {
  let containsAnInlineNode = false;

  doc.nodesBetween(from, to, (node: Node) => {
    containsAnInlineNode = containsAnInlineNode || (node.isInline && node.type === nodeType);

    // Break early now that we know that there is an inline node of the given type
    if (containsAnInlineNode) {
      return false;
    }
  });

  return containsAnInlineNode;
}

/**
Gets a node from the given range only if the range points to the node's start and end position, otherwise returns null.
*/
export function rangeNode($start: ResolvedPos, $end: ResolvedPos): ProseMirrorNode | null {
  const node = $start.nodeAfter;
  if (!node) return null;
  // get the node start position
  const start = Math.max($start.start($start.depth + 1) - 1, 0);
  // check the start of the node
  if ($start.pos !== start) return null;
  // check the end of the node
  if ($end.pos !== start + node.nodeSize) return null;
  return node;
}

/**
Gets the interior range of the given node where 'pos' is the starting position of the node.
This function is useful for the node unbinding.
*/
export function nodeInterior(doc: ProseMirrorNode, node: ProseMirrorNode, pos: number): NodeRange {
  const $from = doc.resolve(pos + 1);
  const $to = doc.resolve(pos + node.nodeSize - 1);
  // in case of childless nodes restore order of positions so the range will contain the whole node
  if ($to.pos < $from.pos) {
    return new NodeRange($to, $from, $to.depth);
  } else {
    return new NodeRange($from, $to, $from.depth);
  }
}

/*
 * Iterating over nodes
 */
// Iterates through the nodes between `from` and `to`. The returned value from iteratee is passed into the child calls to iteratee
// TODO: parent is always undefined
export function nodesBetweenWithData(node: Node, from: number, to: number, iteratee: IterateNodeCallback, data?: any, nodeStart: number = 0, parent: Node = null) {
  for (let i = 0, pos = 0; pos < to; i += 1) {
    const child = node.child(i);
    const end = pos + child.nodeSize;

    if (end > from) {
      const childData = iteratee(child, data, nodeStart + pos, parent, i);

      if (child.content.size) {
        const start = pos + 1;
        nodesBetweenWithData(child, Math.max(0, from - start), Math.min(child.content.size, to - start), iteratee, childData, nodeStart + start);
      }
    }

    pos = end;
  }
}

/**
Gets the top most block nodes that the given range contains.
That means for example in case: <div><p>te|xt</p></div> this method returns <div> node,
but for <div><p>te|xt</p><p>text</p></div> it returns the first <p> node because the range does not have second <p> node,
but for <div><p>te|xt</p><p>te|xt</p></div> it returns again the <div> node because the range has both child <p> nodes.
@param canPreserveInvalidParent - function which allows to determinate whether the node which is parent for other block nodes
but it is not fully selected (only some children are selected) can be preserved in the result. By default such nodes are excluded from the result.
@param canPreserveLeaf - function which allows to determinate whether the deepest node can be included into the result node array
if its parent is valid for the result. If the function returns true the deepest node will be included into result but its parent will be excluded.
if this parameter is not specified such nodes will be excluded from the result leaving only their parents.
*/
export function topBlocksBetween(
  $from: ResolvedPos,
  $to: ResolvedPos,
  canPreserveInvalidParent?: IterateNodeCallback,
  canPreserveLeaf?: IterateNodeCallback): FoundNodeInfo[] {
  const doc = $from.doc;
  const blocks = new Map<number, NodeMapValue>();

  // sort numbers in reverse order
  const descendant = (x: number, y: number): number => {
    return x > y ? -1 : 1;
  };
  // delete the block map entry and its ancestors
  const deleteWithAncestors = (pos: number) => {
    const block = blocks.get(pos);
    if (!block) return;
    blocks.delete(pos);
    deleteWithAncestors(block.parentPos);
  };

  // add <html> node into the map
  blocks.set(0, new NodeMapValue(doc.firstChild, 0, 0, -1));
  // accumulate other selected block nodes into the map
  doc.nodesBetween($from.pos, $to.pos, (node, pos, parent, index) => {
    if (pos === 0 || !node.isBlock) return;
    const $pos = doc.resolve(pos);
    const parentPos = $pos.before($pos.depth);
    const block = new NodeMapValue(node, pos, index, parentPos);
    blocks.set(pos, block);
    blocks.get(parentPos).children.push(block);
  });

  // delete nodes from the map which are not fully selected
  for (let pos of [...blocks.keys()]) {
    const block = blocks.get(pos);
    // leave leaf nodes, they will be handled in the next step
    if (!block || block.children.length == 0) continue;
    // exclude node if not all its children contains selection
    if (block.node.childCount !== block.children.length &&
      // allows preserve the node anyway for special cases
      !canPreserveInvalidParent?.(block.node, blocks, pos, blocks.get(block.parentPos)?.node, block.index)) {
      deleteWithAncestors(pos);
    }
  }

  // delete leaf nodes from the map if its parent is valid for result
  for (let pos of [...blocks.keys()].sort(descendant)) {
    const block = blocks.get(pos);
    if (!block) continue;
    const parent = blocks.get(block.parentPos);
    // parent is invalid, so the leaf node is explicitly valid
    if (!parent) continue;
    // check whether the leaf node can be included into the result
    if (canPreserveLeaf?.(block.node, blocks, pos, parent.node, block.index)) {
      // if yes, delete its ancestors from the map
      deleteWithAncestors(block.parentPos);
    } else {
      // otherwise exclude the leaf node and wait its parent check
      blocks.delete(pos);
    }
  }

  // convert map entries into expected array
  return [...blocks.entries()].map(([key, value]) => {
    return { node: value.node, pos: key };
  });
}

/**
Gets the top most block nodes that the given range contains.
@param predicate - function that allows to exclude deepest block nodes.
if the function returns false then its valid ancestor will be included instead of the ancestor children.
*/
export function deepestBlocksBetween($from: ResolvedPos, $to: ResolvedPos,
  predicate?: IterateNodeCallback): FoundNodeInfo[] {
  let end: number = 0;
  const doc = $from.doc;
  const deepest: FoundNodeInfo[] = [];
  $from.doc.nodesBetween($from.pos, $to.pos, (node, pos, parent, index) => {
    if (isLeafBlock(node)) {
      let nodeEnd = pos + node.nodeSize;
      // the node ancestor is already selected
      if (end > nodeEnd) return;
      if (!predicate || predicate(node, deepest, pos, parent, index))
        // include the node into the result
        deepest.push({ node, pos });
      else {
        // get the valid node ancestor
        const ancestor = resolvedPosFind(doc.resolve(pos), (a, d, $p) => {
          return predicate(a, deepest, $p.before(d), $p.node(d - 1), $p.index(d));
        });
        // exclude the ancestor descants from the result
        const p = ancestor.$nodePos.pos;
        for (let i = deepest.length - 1; i >= 0; i--) {
          if (deepest[i].pos < p) break;
          deepest.pop();
        }
        // include the ancestor into the result
        deepest.push({ node: ancestor.node, pos: p });
        nodeEnd = p + ancestor.node.nodeSize;
      }
      end = nodeEnd;
    }
  });
  return deepest;
};

/**
Gets whether the node is a block that does not contain child blocks.
*/
export function isLeafBlock(node: ProseMirrorNode): boolean {
  return node.isBlock &&
    node.type.name !== 'mcCentralContainer' &&
    (node.inlineContent || node.childCount == 0 ||
      (node.childCount === 1 && node.firstChild.type.name === 'mcCentralContainer'));
}

/**
Gets the nearest block ancestor node info.
*/
export function closestBlockAncestor($pos: ResolvedPos): FoundNodeInfo | null {
  const found = resolvedPosFind($pos, (node) => node.isBlock);
  if (!found) return null;
  return { node: found.node, pos: found.$nodePos.pos };
}

/*
 * Copying nodes
 */

// Copies a single path down a node (depth first) and fills the bottom node with `content`. The bottom node must be empty
export function copyAndFillNode(node: Node, content: Fragment): Node {
  if (node.content.childCount > 0) {
    return node.copy(Fragment.from(copyAndFillNode(node.content.firstChild, content)));
  } else {
    return node.copy(content);
  }
}

export function removeNodeType(node: Node, nodeTypes: NodeType[]): Node {
  if (nodeTypes.includes(node.type)) {
    return null;
  }

  return node.copy(removeNodeTypeFromFragment(node.content, nodeTypes));
}

export function removeNodeTypeFromFragment(fragment: Fragment, nodeTypes: NodeType[]): Fragment {
  const newNodes: Node[] = [];

  fragment.forEach((node: Node) => {
    const newNode = removeNodeType(node, nodeTypes);
    if (newNode) {
      newNodes.push(newNode);
    }
  });

  return Fragment.fromArray(newNodes);
}

export function removeNodeTypeFromSlice(slice: Slice, nodeTypes: NodeType[]): Slice {
  return new Slice(removeNodeTypeFromFragment(slice.content, nodeTypes), slice.openStart, slice.openEnd);
}

/**
 * Returns true if the node's attributes are the same as the given attributes. Otherwise returns false.
 * Returns false if the node is null or undefined.
 * @param node The node to compare the attributes with.
 * @param attrs The attributes to compare with the node.
 */
export function hasNodeAttrs(node: ProseMirrorNode, attrs: Dictionary): boolean {
  if (!node) {
    return false;
  }

  const nodeAttrs = !node.attrs || isEmpty(node.attrs) ? null : node.attrs;
  attrs = !attrs || isEmpty(attrs) ? null : attrs;

  return isEqual(nodeAttrs, attrs);
}

/**
 * Loops through all the children of a node invoking a function for each child.
 * The callback function is given extra information compared to a normal loop through the children.
 * This information includes the document position of the child.
 * @param node The parent to loop through.
 * @param posOfNode The position of the parent node in the document.
 * @param iteratee The callback function to invoke for each child of node.
 */
export function forEachChild(node: ProseMirrorNode, posOfNode: number, iteratee: (childNode: ProseMirrorNode, parentOffset: number, docPos: number, childIndex: number) => void) {
  node.content.forEach((childNode, parentOffset, index) => {
    // Add one to the doc position to account for the parent node's position
    iteratee(childNode, parentOffset, 1 + posOfNode + parentOffset, index);
  });
}

/*
 * Comparing Nodes
 */
export function attrsEq(attrsA: Dictionary, attrsB: Dictionary, customizer?: (valueA: any, valueB: any, name: string) => boolean): boolean {
  return isEqualWith(attrsA, attrsB, customizer);
}

export function marksEq(nodeA: ProseMirrorNode, nodeB: ProseMirrorNode, customizer?: (valueA: any, valueB: any, name: string) => boolean): boolean {
  return isEqualWith(nodeA.marks, nodeB.marks, customizer);
}

export function fragmentEq(fragA: Fragment, fragB: Fragment): boolean {
  if (fragA.childCount !== fragB.childCount) {
    return false;
  }

  for (let i = 0; i < fragA.childCount; i += 1) {
    if (!nodeEq(fragA.child(i), fragB.child(i))) {
      return false;
    }
  }

  return true;
}

/**
 * Compares two nodes to see if they are equal. Uses NodeSpec.eq when it exists.
 * @param nodeA One of the nodes to compare.
 * @param nodeB The other node to compare.
 * @returns Returns true if the nodes are the same.
 */
export function nodeEq(nodeA: ProseMirrorNode, nodeB: ProseMirrorNode): boolean {
  if (typeof nodeA.type.spec.eq === 'function') {
    return nodeA.type.spec.eq(nodeA, nodeB);
  } else {
    if (nodeA === nodeB) {
      return true;
    }

    if (!nodeA.sameMarkup(nodeB)) {
      return false;
    }

    if (!fragmentEq(nodeA.content, nodeB.content)) {
      return false;
    }

    if (nodeA.isText && nodeB.isText && nodeA.text !== nodeB.text) {
      return false;
    }

    return true;
  }
}

export function getLowestLevelContainer(slice: Slice): ProseMirrorNode {
  let content: Fragment = slice?.content;
  while (content) {
    const nextFragment: Fragment = content?.firstChild.content;
    // no next fragment || next fragment has more then one child so lowest level container || last container that allows its content to be replaced
    if (!nextFragment?.size || nextFragment.childCount > 1) {
      // return a node
      return content.firstChild;
    } else {
      // loop a fragment
      content = nextFragment;
    }
  }
}

export interface NodeSpecEq {
  attrs?: (nodeA: ProseMirrorNode, nodeB: ProseMirrorNode) => boolean;
  content?: (nodeA: ProseMirrorNode, nodeB: ProseMirrorNode) => boolean;
  marks?: (nodeA: ProseMirrorNode, nodeB: ProseMirrorNode) => boolean;
  text?: (nodeA: ProseMirrorNode, nodeB: ProseMirrorNode) => boolean;
  type?: (nodeA: ProseMirrorNode, nodeB: ProseMirrorNode) => boolean;
}

/**
 * Creates a function to be used by nodes that override their eq function.
 * Only the custom parts of node comparison need to be passed in via the options parameter.
 * Any options left undefined use the default behavior to compare nodes.
 * For example, to only override how a node's attrs are compared then only the attrs options needs to be defined.
 * @param options
 * @returns Returns a function that takes two nodes as parameters and returns a boolean.
 */
export function nodeSpecEq(options: NodeSpecEq) {
  return (nodeA: ProseMirrorNode, nodeB: ProseMirrorNode): boolean => {
    // Check instance equality
    if (nodeA === nodeB) {
      return true;
    }

    // Check type equality
    if (typeof options.type === 'function') {
      if (!options.type(nodeA, nodeB)) {
        console.log('custom type mismatch', options.type);
        return false;
      }
    } else {
      if (nodeA.type !== nodeB.type) {
        console.log('default type mismatch');
        return false;
      }
    }

    // Check attrs equality
    if (typeof options.attrs === 'function') {
      if (!options.attrs(nodeA, nodeB)) {
        return false;
      }
    } else {
      if (!attrsEq(nodeA.attrs, nodeB.attrs ?? nodeB.type.defaultAttrs ?? {})) {
        return false;
      }
    }

    // Check marks equality
    if (typeof options.marks === 'function') {
      if (!options.marks(nodeA, nodeB)) {
        return false;
      }
    } else {
      if (!Mark.sameSet(nodeA.marks, nodeB.marks ?? Mark.none)) {
        return false;
      }
    }

    // Check content equality
    if (typeof options.content === 'function') {
      if (!options.content(nodeA, nodeB)) {
        return false;
      }
    } else {
      if (!fragmentEq(nodeA.content, nodeB.content)) {
        return false;
      }
    }

    // Check text equality
    if (typeof options.text === 'function') {
      if (!options.text(nodeA, nodeB)) {
        return false;
      }
    } else {
      if (nodeA.isText && nodeB.isText && nodeA.text !== nodeB.text) {
        return false;
      }
    }

    return true;
  };

}

/**
 * Returns the NodeType from a schema given the NodeType or the name of the NodeType.
 * @param nodeTypeOrName The NodeType or the name of the NodeType.
 * @param schema The schema to get the NodeType from.
 * @returns The NodeType.
 */
export function getNodeType(nodeTypeOrName: NodeType | string, schema: Schema): NodeType {
  if (typeof nodeTypeOrName === 'string') {
    return schema.nodes[nodeTypeOrName];
  } else {
    return nodeTypeOrName;
  }
}

/**
 * Returns a copy of a node.
 * @param node The node to copy.
 * @param cloner A function to copy the node - could be used to change parts of the node before its copied.
 * @returns The copied node.
 */
export function cloneNode(node: ProseMirrorNode, cloner: (node: ProseMirrorNode, content: Fragment) => ProseMirrorNode): ProseMirrorNode {
  return node ? cloner(node, node.content ? cloneFragment(node.content, cloner) : null) : null;
}

/**
 * Returns a copy of a fragment.
 * @param fragment The fragment to copy.
 * @param cloner A function to copy the nodes in the fragment - could be used to change parts of the nodes before its copied.
 * @returns The copied fragment.
 */
export function cloneFragment(fragment: Fragment, cloner: (node: ProseMirrorNode, content: Fragment) => ProseMirrorNode): Fragment {
  if (!fragment) return null;
  const nodes: ProseMirrorNode[] = [];
  fragment.forEach(node => {
    nodes.push(cloneNode(node, cloner));
  });
  return Fragment.fromArray(nodes);
}

/**
 * Returns a copy of a slice.
 * @param slice The slice to copy.
 * @param cloner A function to copy the nodes in the slice - could be used to change parts of the nodes before its copied.
 * @returns The copied slice.
 */
export function cloneSlice(slice: Slice, cloner: (node: ProseMirrorNode, content: Fragment) => ProseMirrorNode) {
  return slice ? new Slice(cloneFragment(slice.content, cloner), slice.openStart, slice.openEnd) : null;
}

