import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';

declare global {
  interface Window {
    MathJax: {
      config: {
        tex: texConfig,
        svg: svgConfig
      },
      options: {
        renderActions: {
          addMenu: []
        }
      }
      startup: {
        getComponents: () => void
      };
      typeset: () => void,
      mathml2svg: (equation: string) => HTMLElement,
      tex2svg: (equaition: string) => HTMLElement
    }
  }
}

interface texConfig {
  inlineMath: string[][];
}

interface svgConfig {
  fontCache: string;
}

@Component({
  selector: 'mc-equation',
  templateUrl: './equation.component.html',
  styleUrls: ['./equation.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EquationComponent implements OnChanges, AfterViewInit {
  @HostBinding('class.mc-node-view-text') _nodeViewTextClass: boolean = true;

  @Input() equation: string;

  constructor(private viewContainerRef: ElementRef) { }
  ngAfterViewInit() {
    // set config for mathjax if it has never been set
    if (window.MathJax.config.svg.fontCache !== 'global') {
      const newConfig = {
        tex: {
          inlineMath: [['$', '$'], ['$$', '$$'], ['\\(', '\\)']]
        },
        options: {
          renderActions: {
            addMenu: []
          }
        },
        svg: {
          fontCache: 'global'
        }
      };
      Object.assign(window.MathJax.config, newConfig);
      window.MathJax.startup.getComponents();
      window.MathJax.typeset();
    }
    this.renderAndAddEquationToDOM();

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.equation && !changes.equation.firstChange) {
      this.renderAndAddEquationToDOM();
    }
  }

  renderAndAddEquationToDOM() {
    let renderedEquation: HTMLElement;

    if (this.equation.startsWith('<math') || this.equation.startsWith('<mml:')) {
      renderedEquation = window.MathJax.mathml2svg(this.equation);
    } else {
      renderedEquation = window.MathJax.tex2svg(this.removeEquationDelimiters(this.equation));
    }

    this.viewContainerRef.nativeElement.replaceChildren(renderedEquation);
  }

  // removes () and $ delimiters off the equation
  removeEquationDelimiters(equation: string): string {
    return equation.replace(/^(\$|\/\(|\/\))+|(\$|\/\(|\/\))+$/g, "");
  }
}
