import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TextEditorModule } from '@portal-core/text-editor/components/text-editor/text-editor.module';
import { SoloFileTextEditorComponent } from './solo-file-text-editor.component';

@NgModule({
  declarations: [
    SoloFileTextEditorComponent
  ],
  imports: [
    CommonModule,
    TextEditorModule
  ],
  exports: [
    SoloFileTextEditorComponent
  ]
})
export class SoloFileTextEditorModule { }
