import { formatDate, formatNumber } from '@angular/common';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { hasBOM, stripBOM } from '@common/html/util/xml';
import { words } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class StringService {
  constructor(@Inject(LOCALE_ID) public locale: string) { }

  // Returns true if the given string contains only digits
  hasOnlyDigits(value: string): boolean {
    return /^\d+$/.test(value);
  }

  hasBOM(value: string): boolean {
    return hasBOM(value);
  }

  stripBOM(value: string): string {
    return stripBOM(value);
  }

  stripHTMLTags(text: string): string {
    return text.replace(/<[^>]*>/g, '');
  }

  initials(value: string, length: number = 2): string {
    return words(value)?.slice(0, length)?.map(word => word[0])?.join('');
  }

  getFilenameFromUrl(url: string) {
    try {
      return url.substring(url.lastIndexOf('/') + 1);
    } catch {
      return url;
    }
  }

  /**
   * Transforms a number into a string, formatted according to the current locale rules that determine group sizing and separator, decimal-point character, and other locale-specific configurations.
   * Uses Angular's formatNumber function with the current locale.
   * @param value The number to be formatted.
   * @param digitsInfo Decimal representation options, specified by a string in the following format:
   * {minIntegerDigits}.{minFractionDigits}-{maxFractionDigits}.
   *
   * minIntegerDigits: The minimum number of integer digits before the decimal point. Default is 1.
   * minFractionDigits: The minimum number of digits after the decimal point. Default is 0.
   * maxFractionDigits: The maximum number of digits after the decimal point. Default is 3.
   */
  formatNumber(value: number, digitsInfo?: string): string {
    return formatNumber(value, this.locale, digitsInfo);
  }

  /**
   * Transforms a date into a string, formatted according to the current locale rules that determine group sizing and separator, decimal-point character, and other locale-specific configurations.
   * Uses Angular's formatDate function with the current locale.
   * @param date The date object to be formatted.
   * @param dateFormat The format of the date, such as 'YYYY-MM-DD'.
   */
  formatDate(date: Date | string, dateFormat: string) {
    return formatDate(date, dateFormat, this.locale);
  }

  // encodes a uri component, including the parentheses.
  encodeURIComponent(component: string): string {
    return encodeURIComponent(component).replace(/\(/g, '%28').replace(/\)/g, '%29');
  }

  // checks two strings if they are substrings without being case sensitive.
  includesCaseInsensitive(str1: string, str2: string): boolean {
    return str1.toLowerCase().includes(str2.toLowerCase());
  }

  truncatePath(path: string): string {
    let pathSplit: string[] = path.split('/');
    let middleOfPath = Math.floor(pathSplit.length / 2);
    let centerOfPathIndex = middleOfPath - 1 != 0 ? middleOfPath - 1 : middleOfPath;
    // check the middle and then start checking from the left to the middle
    let foundOnLeft = false;
    for (let i = centerOfPathIndex; i >= 0; i--) {
      const truncateFilePartFound = this.checkToTruncatePart(pathSplit[i], pathSplit, i, i === pathSplit.length - 1);
      if (truncateFilePartFound) {
        foundOnLeft = true;
        break;
      }
    }
    //check the right if nothing was updated on the left
    if (!foundOnLeft) {
      for (let i = centerOfPathIndex + 1; i < pathSplit.length; i++) {
        const truncateFilePartFound = this.checkToTruncatePart(pathSplit[i], pathSplit, i, i === pathSplit.length - 1);
        if (truncateFilePartFound) {
          break;
        }
      }
    }
    return pathSplit.join('/')
  }

  private checkToTruncatePart(pathPart: string, pathSplit: string[], indexToCheck: number, fileName: boolean): boolean {
    const pathPartAlreadyTruncated = pathPart.includes('...');
    // truncate filename only if the fileName is what is left
    if (fileName && pathSplit.length === 1) {
      //truncate in the middle of fileName.
      if (Math.floor(pathPart.length / 2) > 3 && pathPart !== '...') {
        const halfOfFileName = Math.floor(pathPart.length / 2);
        const leftHalf = pathPart.substring(0, Math.floor(halfOfFileName / 2));
        const rightHalf = pathPart.substring(halfOfFileName + leftHalf.length);
        pathSplit[indexToCheck] = leftHalf + '...' + rightHalf;
        return true;
      } else if (pathPart != '...') {
        //truncate all of it
        pathSplit[indexToCheck] = '...';
        return true;
      }
    }
    // truncate the folder
    else if (!fileName && !pathPartAlreadyTruncated) {
      pathSplit[indexToCheck] = '...';
      return true;
    }
    // remove truncated folders that are next to the one you are on.
    if (pathSplit[indexToCheck - 1] === '...') {
      pathSplit.splice(indexToCheck - 1, 1);
      return true;
    }
    else if (pathSplit[indexToCheck + 1] === '...') {
      pathSplit.splice(indexToCheck + 1, 1);
      return true;
    }
    return false;
  }


}
