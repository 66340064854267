<mc-loader *ngIf="loadingState.loading$ | async"></mc-loader>
<form *ngIf="!(loadingState.loading$ | async)" class="mc-spring-board" [formGroup]="imageForm" (ngSubmit)="onSubmit()">

  <mat-toolbar class="mc-spring-board-header" color="secondary">
    {{initialSrc ? 'Edit' : 'Insert'}} Image
    <span class="mc-fill-remaining-flex-space"></span>
    <button mat-icon-button color="primary" type="button" tabindex="-1" (click)="closeDialog()">
      <mat-icon fontSet="project-titan" fontIcon="icon-close"></mat-icon>
    </button>
  </mat-toolbar>

  <div class="mc-spring-board-form-body">
    <div class="mc-project-files-image-dialog-split-view">
      <div class="mc-project-files-image-dialog-selector-container">
        <ng-container *ngIf="!isReview; else reviewImagePicker">
          <mat-form-field class="mc-form-field" appearance="standard" color="primary-vivid">
            <mat-label>From</mat-label>
            <mat-select #imageTypeSelect formControlName="from" placeholder="Image From">
              <mat-option [value]="'fromProject'">
                Project
              </mat-option>
              <mat-option [value]="'fromWeb'">
                Web
              </mat-option>
              <mat-option [value]="'fromDataUri'">
                Data URI
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field *ngIf="imageTypeSelect.value === 'fromProject'" appearance="standard" color="primary-vivid" class="mc-form-field">
            <mat-label>File</mat-label>
            <mc-project-file-picker-input formControlName="projectImagePath" [branchName]="data.commitId" [projectId]="data.projectId" [fileFilter]="imageFilter" rootFolder="Content" [defaultPath]=" ProjectFolder.Images + '/'" [popupHeight]="280" [popupWidth]="456" required></mc-project-file-picker-input>
            <mat-error>
              <mc-form-control-error-message [form]="imageForm" controlName="projectImagePath" type="mc_image"></mc-form-control-error-message>
            </mat-error>
          </mat-form-field>
          <mat-form-field *ngIf="imageTypeSelect.value === 'fromWeb'" appearance="standard" class="mc-form-field" color="primary-vivid">
            <mat-label>Web</mat-label>
            <input matInput type="text" formControlName="webLink" placeholder="Image From Web" required>
            <mat-error>
              <mc-form-control-error-message [form]="imageForm" controlName="webLink" type="mc_image"></mc-form-control-error-message>
            </mat-error>
          </mat-form-field>
          <mat-form-field *ngIf="imageTypeSelect.value === 'fromDataUri'" appearance="standard" class="mc-form-field" color="primary-vivid">
            <mat-label>Data URI</mat-label>
            <textarea class="mc-project-files-image-dialog-data-uri-input" matInput formControlName="dataUri" placeholder="Ex. data:image/png;base64,iVBORw0..." required></textarea>
            <mat-error>
              <mc-form-control-error-message [form]="imageForm" controlName="dataUri" type="mc_image"></mc-form-control-error-message>
            </mat-error>
          </mat-form-field>
          <p *ngIf="imageTypeSelect.value === 'fromDataUri'" class="mc-form-note">Data URIs embed image data into the document (e.g., HTML).</p>
        </ng-container>
        <ng-template #reviewImagePicker>
          <mat-form-field appearance="standard" class="mc-form-field" color="primary-vivid">
            <mat-label>File</mat-label>
            <mc-file-picker [accept]="imageAcceptAttr" (valueChange)="onNewImageFileSelected($event)" layout="field" color="primary-vivid" formControlName="newFile" placeholder="Select Your Image" required></mc-file-picker>
            <button matSuffix mat-icon-button type="button" color="secondary">
              <mat-icon fontSet="project-titan" fontIcon="icon-meatballs-solid"></mat-icon>
            </button>
            <mat-error>
              <mc-form-control-error-message [form]="imageForm" controlName="newFile" type="mc_image"></mc-form-control-error-message>
            </mat-error>
          </mat-form-field>
        </ng-template>
      </div>

      <div class="mc-project-files-image-dialog-preview-container">
        <p class="mc-form-label mc-project-files-image-dialog-preview-label">Preview</p>
        <div class="mc-project-files-image-dialog-preview mc-border-divider">
          <img #imagePreview *ngIf="previewSrc && !isTif && !(loadingState.loading$ | async)" [class.mc-visibly-hidden-out-of-flow]="!previewSrc && (previewLoadingState.loading$ | async) || (previewLoadingState.generalError$ | async)" [src]="previewSrc" alt="Image Preview" (load)="onPreviewLoad()" (error)="onPreviewError()">
          <p *ngIf="isTif" class="mc-text-muted">Unable to load image.</p>
          <mc-loader *ngIf="previewLoadingState.loading$ | async"></mc-loader>
          <mc-error-code-expansion-panel *ngIf="previewLoadingState.generalError$ | async as generalErrorCode" [generalErrorCode]="generalErrorCode"></mc-error-code-expansion-panel>
        </div>
      </div>
    </div>

    <mat-form-field appearance="standard" class="mc-form-field" color="primary-vivid">
      <mat-label>Alternate Text</mat-label>
      <input matInput type="text" formControlName="alt" placeholder="Alternate Text">
      <mat-error>
        <mc-form-control-error-message [form]="imageForm" controlName="alt"></mc-form-control-error-message>
      </mat-error>
    </mat-form-field>

    <div class="mc-form-fields-row">
      <mat-form-field appearance="standard" class="mc-form-field" color="primary-vivid">
        <mat-label>Width</mat-label>
        <mc-style-picker-input #stylePickerWidthInput formControlName="width" label="Width" [presets]="presets"></mc-style-picker-input>
        <button matSuffix mat-icon-button type="button" color="secondary" (click)="stylePickerWidthInput.openPopup()">
          <mat-icon fontSet="project-titan" fontIcon="icon-ruler"></mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field appearance="standard" class="mc-form-field" color="primary-vivid">
        <mat-label>Height</mat-label>
        <mc-style-picker-input #stylePickerHeightInput formControlName="height" label="Height" [presets]="presets"></mc-style-picker-input>
        <button matSuffix mat-icon-button type="button" color="secondary" (click)="stylePickerHeightInput.openPopup()">
          <mat-icon fontSet="project-titan" fontIcon="icon-ruler"></mat-icon>
        </button>
      </mat-form-field>
    </div>
  </div>

  <div class="mc-spring-board-form-footer">
    <div class="mc-form-buttons">
      <button type="button" mat-stroked-button (click)="closeDialog()">Cancel</button>
      <button type="submit" mat-raised-button color="primary" [disabled]="!imageForm.valid || (previewLoadingState.generalError$ | async)">
        {{initialSrc ? 'Save ' : 'Insert '}}
      </button>
    </div>
  </div>
</form>