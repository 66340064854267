import { EquationNodeViewComponent } from '@portal-core/text-editor/components/equation-node-view/equation-node-view.component';
import { NodeViewType } from '@portal-core/text-editor/enums/node-view-type.enum';
import { ContentNodeViewOptions } from '@portal-core/text-editor/models/content-node-view-options.model';
import { ComponentNodeView } from '@portal-core/text-editor/node-views/component.node-view';
import { DynamicViewComponentInjector } from '@portal-core/text-editor/types/dynamic-view-component-injector.type';
import { GetPosForNodeView } from '@portal-core/text-editor/types/nodeview-get-pos.type';
import { ProseMirrorNode } from 'prosemirror-model';
import { Decoration, EditorView } from 'prosemirror-view';

export class EquationNodeView extends ComponentNodeView {
  constructor(
    node: ProseMirrorNode,
    view: EditorView,
    getPos: GetPosForNodeView,
    decorations: Decoration[],
    create: DynamicViewComponentInjector,
    protected options?: ContentNodeViewOptions
  ) {
    super(EquationNodeViewComponent, node, view, getPos, decorations, create, options);
  }

  onComponentCreated() {
    this.outputs.load = () => this.emitLoaded({ type: NodeViewType.Equation });
  }

  updateComponent() {
    let equation: string;
    if (this.node.type.name === 'math' || this.node.type.name === 'mmlmath') {
      equation = this.node.attrs.content;
    } else {
      const firstChild = this.node.firstChild;
      // the equation node could either be in the form of <equation><math> or just <math>;
      // the actual equation is within the math node.
      if (firstChild?.type.name === 'math' || firstChild?.type.name === 'mmlmath') {
        equation = firstChild.attrs.content;
      } else if (firstChild.isText) {
        equation = firstChild.textContent;
      }
    }

    // Update the component's data
    this.inputs.equation = equation ?? '';
  }
}
