import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, ViewEncapsulation } from '@angular/core';
import { NodeViewBase } from '@portal-core/text-editor/util/node-view.base';

@Component({
  selector: 'mc-equation-node-view',
  templateUrl: './equation-node-view.component.html',
  styleUrls: ['./equation-node-view.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EquationNodeViewComponent extends NodeViewBase {
  @Input() equation: string;

  constructor(protected changeDetectorRef: ChangeDetectorRef, protected elementRef: ElementRef) {
    super(changeDetectorRef, elementRef);

  }

  public get parentClassNameSuffix(): string {
    return 'equation-node-view';
  }
}
